import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'language.code',
        maxlength: 2,
        textTransform: 'lowercase',
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}
export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
